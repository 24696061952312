import _index from "./index";
var exports = {};
var tokenize = _index;
exports = tokenizeString;

function tokenizeString(str, opt) {
  var generator = tokenize(opt);
  var tokens = [];
  tokens = tokens.concat(generator(str));
  tokens = tokens.concat(generator(null));
  return tokens;
}

export default exports;